import logo from "./assets/logo.png";
import card1 from "./assets/1.jpg";
import card2 from "./assets/2.jpg";
import card3 from "./assets/3.jpg";
import card4 from "./assets/4.jpg";
import card5 from "./assets/5.jpg";
import card6 from "./assets/6.jpg";
import card7 from "./assets/7.jpg";
import card8 from "./assets/8.jpg";

import "./App.css";

import { useEffect, useState } from "react";
import { Button, Drawer, Radio, Space } from "antd";
import * as React from "react";

const contacts = [
  {
    img: card2,
    c1: "+226 76 60 01 92",
    c2: "+225 07 08 90 95 95",
    c3: "+33 749 88 45 88",
    email: "i.sidibe@focusgroup.africa",
    name: "focus group",
    address: "10 BP 635 Ouagadougou 10",
    web: "https://www.focusgroup.africa"
  },
  {
    img: card3,
    c1: "+226 76 60 01 92",
    c2: "+226 70 32 03 04",
    c3: null,
    email: "i.sidibe@digitalbusiness-school.africa",
    name: "Digital Business School",
    address: "10 BP 635 Ouagadougou 10",
    web: "https://www.digitalbusiness-school.africa"
  },
  {
    img: card1,
    c1: "+226 76 60 01 92",
    c2: "+225 07 08 90 95 95",
    c3: "+33 749 88 45 88",
    email: "i.sidibe@smartequipments.africa",
    name: "smart equipments",
    address: "10 BP 635 Ouagadougou 10",
    web: "https://www.smartequipments.africa"
  },
  {
    img: card4,
    c1: "+226 76 60 01 92",
    c2: "+226 70 32 03 04",
    email: "izouma.sidibe@ubagroup.africa",
    name: "united bank for africa",
    address: "1340, Avenue Dimdolobsom, Ouagadougou",
    web: "https://www.ubagroup.com"
  },
  {
    img: card5,
    c1: "+226 76 60 01 92",
    c2: "+33 749 88 45 88",
    c3: "+225 07 08 90 95 95",
    email: "i.sidibe@focusportfc.africa",
    name: "focus sport fc",
    address: "10 BP 635 Ouagadougou 10",
    web: "https://www.focusportfc.africa"
  },
  // {
  //   img: card6,
  //   c1: "+226 76 60 01 92",
  //   c2: "+33 749 88 45 88",
  //   c3: "+225 07 08 90 95 95",
  //   email: "i.sidibe@focusgroup.africa",
  //   name: "FOCUSPORT 1XBET",
  //   address: "10 BP 635 Ouagadougou 10",
  //   web: "https://www.focusgroup.africa"
  // },
  {
    img: card7,
    c1: "+226 76 60 01 92",
    c2: "+33 749 88 45 88",
    c3: "+225 07 08 90 95 95",
    email: "i.sidibe@globus-energy.africa",
    name: "Globus Energy",
    address: "BP 635 Ouagadougou 10",
    web: "https://www.globus-energy.africa"
  },
  {
    img: card8,
    c1: "+226 76 60 01 92",
    c2: "+225 07 08 90 95 95",
    c3: "+33 749 88 45 88",
    email: "i.sidibe@focusgroup.africa",
    name: "Focus Lab",
    address: "10 BP 635 Ouagadougou 10",
    web: "https://www.focusgroup.africa"
  }
];

function App() {
  const [selected, setSelected] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {}, []);

  const onClose = () => {
    setSelected(null);
    setDrawerOpen(false);
  };
  return (
    <div className="App">
      <header className="App-header">
        <div className="header-top">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Visitez mon site{" "}
            <a
              href={"https://izoumasidibe.com"}
              style={{
                color: "#10A2EA"
              }}
            >
              www.izoumasidibe.com
            </a>
          </p>
        </div>
        <span
          style={{
            fontSize: 18,
            textAlign: "center",
            fontWeight: 600,
            marginTop: 20
          }}
        >
          Chevalier de l'Ordre de Mérite des Arts, Lettres et de la
          Communication
        </span>
        <p
          style={{
            marginTop: 30
          }}
        >
          <code
            style={{
              fontSize: 15
            }}
          >
            Sélectionnez une carte pour accéder aux détails
          </code>
          .
        </p>
        <div className="card-block">
          {contacts.map((item) => (
            <div
              className="card-view"
              onClick={() => {
                setSelected(item);
                setDrawerOpen(true);
              }}
            >
              <img src={item?.img} alt="logo" />
            </div>
          ))}
        </div>
        <div
          style={{
            height: 100
          }}
        />
      </header>
      <Drawer
        title={"Carte de visite"}
        placement={"bottom"}
        closable={true}
        onClose={onClose}
        open={drawerOpen}
        key={"bottom"}
        mask
        maskClosable
        height={"70%"}
        style={
          {
            // backgroundColor: "red"
          }
        }
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            textAlign: "center"
          }}
        >
          <p
            style={{
              fontSize: 25,
              fontWeight: 800
            }}
          >
            {selected?.name?.toUpperCase()}
          </p>
          <a
            href={`http://maps.google.com/?q=:${selected?.address}`}
            style={{
              fontSize: 20,
              backgroundColor: "#fff",
              padding: 10,
              borderRadius: 10,
              border: "1px solid #cecece",
              color: "red"
            }}
          >
            Voir adresse google map
          </a>

          <a
            href={selected?.web}
            style={{
              fontSize: 20,
              backgroundColor: "#fff",
              color: "red",
              padding: 10,
              borderRadius: 10,
              border: "1px solid #cecece",
              marginTop: 20
            }}
          >
            Voir site web
          </a>

          <a
            href={`mailto:${selected?.email}`}
            style={{
              fontSize: 20,
              backgroundColor: "#fff",
              padding: 10,
              borderRadius: 10,
              border: "1px solid #cecece",
              marginTop: 20,
              color: "red"
            }}
          >
            Envoyer un email
          </a>
          {selected?.c1 && (
            <a
              href={`tel:${selected?.c1}`}
              style={{
                fontSize: 20,
                backgroundColor: "#fff",
                padding: 10,
                borderRadius: 10,
                border: "1px solid #cecece",
                marginTop: 20,
                color: "red"
              }}
            >
              Contacter au {selected?.c1}
            </a>
          )}
          {selected?.c2 && (
            <a
              href={`tel:${selected?.c2}`}
              style={{
                fontSize: 20,
                backgroundColor: "#fff",
                padding: 10,
                borderRadius: 10,
                border: "1px solid #cecece",
                marginTop: 20,
                color: "red"
              }}
            >
              Contacter au {selected?.c2}
            </a>
          )}
          {selected?.c3 && (
            <a
              href={`tel:${selected?.c3}`}
              style={{
                fontSize: 20,
                backgroundColor: "#fff",
                color: "red",
                padding: 10,
                borderRadius: 10,
                border: "1px solid #cecece",
                marginTop: 20
              }}
            >
              Contacter au {selected?.c3}
            </a>
          )}
        </div>
      </Drawer>
    </div>
  );
}

export default App;
